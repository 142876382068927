import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './gen-com/login/login.component';
import { RegisterComponent } from './gen-com/register/register.component';
import { AuthGuard } from './guards/auth.guard';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ContactComponent } from './gen-com/contact/contact.component';
import { NotLoadGuard } from './guards/not-load.guard';

import { MarketingMaterialComponent } from './modules/marketingmaterial/marketingmaterial.component';

const routes: Routes = [
  {path:"", component:DashboardComponent, canActivate:[AuthGuard]},
  {path:"login", component:LoginComponent,canActivate:[NotLoadGuard]}, 
  {path:"register", component:RegisterComponent,canActivate:[NotLoadGuard]},
  {path : "user", loadChildren :() => import('./modules/contacts/contacts.module').then(m => m.ContactsModule), canActivate:[AuthGuard] },
  {path : "packages", loadChildren :() => import('./modules/packages/packages.module').then(m => m.PackagesModule), canActivate:[AuthGuard] },
  {path : "quotes", loadChildren :() => import('./modules/quotes/quotes.module').then(m => m.QuotesModule), canActivate:[AuthGuard] },
  {path : "dashboard", component:DashboardComponent,canActivate:[AuthGuard] },
  {path : "contact", component:ContactComponent, canActivate:[AuthGuard]},
  {path : "salesorder", loadChildren :() => import('./modules/salesorder/salesorder.module').then(m => m.SalesOrderModule), canActivate:[AuthGuard]  },
  {path : "invoices", loadChildren :() => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule), canActivate:[AuthGuard]  },
  
  {path : "marketing_material", component:MarketingMaterialComponent,canActivate:[AuthGuard] },
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
