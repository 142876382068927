import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/gen-service/user.service';

@Component({
  selector: 'app-content',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  addressinfo: any;
 
  constructor(private usrSer:UserService) { }

  ngOnInit() {
    this.companyInformation();
  }
  companyInformation(){
    this.usrSer.CompanyAddress().subscribe(
      companyinfo => {
        this.addressinfo = companyinfo[0];
      })
  }
}
