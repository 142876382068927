import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NgbDate, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  public  fromDate: NgbDate;
  constructor(private calendar: NgbCalendar) {
    this.fromDate = calendar.getToday();
   }
  private userLogged = new BehaviorSubject(!!sessionStorage.getItem('user'));
  authUser = this.userLogged.asObservable();

  

  changeUserStatus(data: boolean) {
    this.userLogged.next(data);
   
  }

  ddmmyy_date(dt){
    
      if(typeof dt != "undefined"){
        let dat = dt.split("-");
        return  { day: parseInt(dat[0]), month: parseInt(dat[1]), year: parseInt(dat[2]) };
      }else{
        return null;
      }
  
  }

  ddmmyy_merge(dt){ 
    let dat = dt
    if(typeof dt == "object"){
      dat = dt.day+"-"+dt.month+"-"+dt.year; 
    }
    return  dat;
   }

  

  loggedIn(){
    let user = sessionStorage.getItem('user');
    let ret;
    if(user==null){
      ret = false;
    }else{
       ret = true;
    }
    return ret;
  }


}
