import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/gen-service/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  public profile:any;
  imgpath: string;
  constructor(public userSer:UserService) { }

  ngOnInit() {
    this.profile = this.userSer.currentUserDetails();
    this.imgpath = this.userSer.path;
  }
 

  log_out(){
    this.userSer.logoutUser();
  }
}
