import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/gen-service/shared.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { UserService } from 'src/app/gen-service/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private share:SharedService, private actroute:ActivatedRoute, private route:Router, private usrSer:UserService) { }
  public errMsg:any = false;
  public regStatus:any = false;
  public for_pass:any = false;
  public loder:boolean = false;

  imageInfoArray: any[] = [];

  ngOnInit() {
   
    this.actroute.queryParams.subscribe(
      (param) =>{ 
        if(param.length !=0){
          this.regStatus = param.status != null ? true : false;
          this.for_pass = param.forgot != null ? true : false;
        }
      }
    )

    this.getSliderImages();
   
  }

  loginUser(loginFrm){
    this.loder = true;
    this.usrSer.login_user(loginFrm.value).subscribe(
      data=>this.navigateUser(data),
      err => console.log(err),
      () => this.loder = false
    )    
  }
  navigateUser(data){
    this.loder = false;  
    console.log(data)
    // return false;  
    if(data[0] == 'INVALID_USERNAME_OR_PASSWORD'){
      // this.errMsg = "Inavalid User Name or Password"; 
      this.errMsg = data[1];
    }else{
      this.share.changeUserStatus(true);
      let usr:any = data[0];
      usr.login_time = Date.now().toString(); 
      sessionStorage.setItem('user',JSON.stringify(usr));
      this.route.navigate(["/dashboard"]);
      // if(usr.mailingcity=="" || usr.mailingstreet=="" || usr.mailingcountry==""){
      //   this.route.navigate(["/user/edit-profile"],{ queryParams: { errmsg : 'addr_mt'} });
      // }else{
      //   this.route.navigate(["/dashboard"]);
      // }
      
    }
  }

  getSliderImages(): void {
    this.loder = true;

    this.usrSer.getImageInfo().subscribe(
      (data: any[]) => {
        this.imageInfoArray = data;
        this.loder = false;
      }
    );
  } 

}
