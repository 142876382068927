import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/gen-service/user.service';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit  {

  public loder:boolean = false;
  public errMsg:any = false;
  public errCom:any = false;
  public difines = ['FIT','MICE','Groups','Niche','Educational','Sports'];
  constructor(private usrSer:UserService, private route:Router) { }

  results: any[] = [];
  queryField: FormControl = new FormControl();
 ngOnInit() {
   this.queryField.valueChanges.
   subscribe(queryField =>this.usrSer.companysearch(queryField).subscribe(
      data => {
          this.results = data;
      }
      ))
 }
  createUser(data){
    this.loder = true; 
    data.definebusiness = this.selDefin.toString();
    this.usrSer.createUser(data).subscribe(
      res => {
        if(res[0].status){
          this.navigateUser(res)
        }else{
          
          if(res[0].err == "company"){
            this.errMsg = false;
            this.errCom = true;
          }else{
            this.errMsg = true;
            this.errCom = false;
          }
        }
      },
      err => console.log(err),
      () => this.loder = false
    ) 
  }
  public selDefin = []
  addDifines(chkDfn){ 
    if(chkDfn.checked){
      if(this.selDefin.indexOf(chkDfn.value) ==-1 ){
        this.selDefin.push(chkDfn.value);
      }
    }else{
      if(this.selDefin.indexOf(chkDfn.value) !=-1 ){
        this.selDefin = this.selDefin.filter(item=>chkDfn.value!=item);
        console.log(this.selDefin)
      }
    } 
  }
  check_unique(em){
    this.usrSer.getUserunique(em).subscribe(
      data => {
        if(!data[0].status){
          if(data[0].err == "company"){
            this.errCom = true; 
            this.errMsg = false;
          }else{
            this.errCom = false; 
            this.errMsg = true;
          }
            
        }else{
          this.errMsg = false;
          this.errCom = false;
        }
      },
      err => console.log(err)
    )
  }

  navigateUser(err){
    this.loder = false;
    this.route.navigate(['/login'], { queryParams: { status: 'created'} });
    // this.route.navigate(['/login'], { queryParams: { status: 'created' } });
  }

}
