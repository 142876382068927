import { Component, OnInit } from '@angular/core';
import { QuotesService } from '../quotes/quotes.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public quotecounts:any = [];
  public loder:boolean = true;
  public errStandard:any = false;
  public errTDU:any = false;
  public errCustom:any = false;
  constructor(private qSer:QuotesService, public route:Router) { }

  public notifications:any = [];
  //notifications: { notificationText: string, serverPath: string, notificationCategory: string }[] = [];

  ngOnInit() {
    this.myQuoteCountDetails();
    
    this.showNotifications();
  }
  myQuoteCountDetails(){
    this.qSer.getQuoteCountDetails().subscribe(
      data => {
        if(typeof data[0] == "object"){           
              this.quotecounts = data[0];            
        } else{
          this.quotecounts = "";
        }
       
      },
      err => console.log(err),
      () => this.loder = false
    )
  }

  createStandard(land,hotel,closeModal){
    if(land.checked || hotel.checked){
      closeModal.click();
      let val = land.checked ? land.value : hotel.value;
      this.route.navigate(['/quotes/standard-quote-create'], { queryParams: { quoteType : 'Standard_Itinerary_Quote', values: val } })
    }else{
      this.errStandard = "Please select One option";
    }
  }
  //TDU
  createTDU(land,hotel,closeModal){
    if(land.checked || hotel.checked){
      closeModal.click();
      let val = land.checked ? land.value : hotel.value;
      this.route.navigate(['/quotes/tdu-quotes'], { queryParams: { quoteType : 'TDU_Packages', values: val } })
    }else{
      this.errTDU = "Please select One option";
    }
  }
  //Custom
  createCustomPackage(land,hotel,closeModal){
    if(land.checked || hotel.checked){
      closeModal.click();
      let val = land.checked ? land.value : hotel.value;
      this.route.navigate(['/quotes/custom-quotes'], { queryParams: { quoteType : 'Custom_Quote', values: val } })
    }else{
      this.errCustom = "Please select One option";
    }
  }


  //code by kp
  showNotifications(){
    this.qSer.getNotifications().subscribe(
      data => {
        console.log(data);
        this.notifications = data;

        /*for (const key in data) {
          if (Object.prototype.hasOwnProperty.call(data, key)) {
            var notification = data[key];
            var notificationText = notification.notificationText;
            var notificationCategroy = notification.notificationCategroy;
          }
        }*/        
      }
    )
  }//end of function
}
