import { Component, OnInit } from '@angular/core';
import { marketingmaterialService } from '../marketingmaterial/marketingmaterial.service';
/*import { HttpClient, HttpHeaders } from '@angular/common/http';*/

@Component({
  selector: 'app-dashboard',
  templateUrl: './marketingmaterial.component.html',
  styleUrls: ['./marketingmaterial.component.css']
})
export class MarketingMaterialComponent implements OnInit {
  public quotecounts:any = [];
  public loder:boolean = true;
  public errStandard:any = false;
  public errTDU:any = false;
  public errCustom:any = false;

  materialInfoArray: any[] = [];  
  fileInfoArray: any[] = [];

  resources: string[] = [];
  filteredResources: string[] = [];
  selectedResource: string = '';

  materials: string[] = [];
  filteredMaterials: string[] = [];
  selectedMaterial: string = '';

  constructor(private qSer:marketingmaterialService) { }

  ngOnInit() {
    //this.myQuoteCountDetails();
    this.getMaterialImages();
  }

  downloadImage(filename, imageUrl) {
    this.qSer.downloadImage(imageUrl).subscribe((blob) => {
      this.createDownloadLink(blob, filename);
    });
  }

  private createDownloadLink(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }

  getMaterialImages(): void {
    this.loder = true;

    this.qSer.getImageInfo().subscribe(
      (data: any[]) => {
        this.materialInfoArray = data;
      }
    );

    //for Educational Resources module
    this.qSer.getEducationalResourcesFileInfo().subscribe(
      (result: any[]) => {
        this.loder = false;
        if (result !== null && result !== undefined) {
          this.fileInfoArray = result;
          this.resources = this.fileInfoArray.map(fileInfo => fileInfo.educationalResourcesText);
        }
      }
    );
  }

  searchResource(event: any): void {
    // Access the value of the input field using event.target.value
    const searchTerm: string = event.target.value.toLowerCase();
    
    // Filter the resources based on the search term
    this.filteredResources = this.resources.filter(resource =>
      resource.toLowerCase().includes(searchTerm)
    );

    if(searchTerm == ''){
      this.qSer.getEducationalResourcesFileInfo().subscribe(
        (result: any[]) => {
          this.fileInfoArray = result;
        }
      );
    }    
  }

  selectResource(resource: any): void {
    this.selectedResource = resource;
    this.filteredResources = []; // Clear the filtered list after selection

    this.qSer.getEducationalResourcesFilteredFileInfo(this.selectedResource).subscribe(
      (res: any[]) => {
        this.fileInfoArray = res;
        this.loder = false;
      }
    );
  }

  selectResourceOnClick(): void {
    this.loder = true;

    // This method is triggered when the button is clicked
    if (this.selectedResource) {
      this.selectResource(this.selectedResource);
    } else {
      console.log('No resource selected. Perform your search logic here.');
      // You can perform your search logic directly here if no resource is selected
    }
  }

  searchMaterial(event: any): void {
    // Access the value of the input field using event.target.value
    const searchTerm: string = event.target.value.toLowerCase();
    
    // Filter the resources based on the search term
    this.filteredMaterials = this.materials.filter(material =>
      material.toLowerCase().includes(searchTerm)
    );

    if(searchTerm == ''){
      this.qSer.getImageInfo().subscribe(
        (data: any[]) => {
          this.materialInfoArray = data;
        }
      );
    }    
  }

  selectMaterial(material: any): void {
    // Your existing selectResource method logic
    this.selectedMaterial = material;
    this.filteredMaterials = []; // Clear the filtered list after selection

    this.qSer.getMarketingMaterialFilteredFileInfo(this.selectedMaterial).subscribe(
      (res: any[]) => {
        this.materialInfoArray = res;
        this.loder = false;
      }
    );
  }

  selectMaterialOnClick(): void {
    this.loder = true;
    // This method is triggered when the button is clicked
    if (this.selectedMaterial) {
      this.selectMaterial(this.selectedMaterial);
    } else {
      console.log('No material selected.');
    }
  }
}