import { BrowserModule } from '@angular/platform-browser';
import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http'; 

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './gen-com/header/header.component';
import { SidebarComponent } from './gen-com/sidebar/sidebar.component';
import { ContactComponent } from './gen-com/contact/contact.component';
import { LoginComponent } from './gen-com/login/login.component';
import { RegisterComponent } from './gen-com/register/register.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ForgotPasswordComponent } from './gen-com/forgot-password/forgot-password.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatTabsModule } from '@angular/material/tabs';
import { MarketingMaterialComponent } from './modules/marketingmaterial/marketingmaterial.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    ContactComponent,
    LoginComponent, 
    RegisterComponent,
    ForgotPasswordComponent,    
    DashboardComponent,
    MarketingMaterialComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTabsModule,
  ],
  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent],
  schemas : [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
