import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { SharedService } from './shared.service';

const MINUTES_UNITL_AUTO_LOGOUT = 30;
const CHECK_INTERVAL = 5000 // in ms
@Injectable({
  providedIn: 'root'
})
export class UserService {

  // live
  public serverUrl = "https://crm.turtledownunder.com.au/TravelSoap.php";
  public path:string = "https://crm.turtledownunder.com.au/";

   // local
  /*public serverUrl = "https://crm.turtledownunder.com.au/stagging/TravelSoap.php";
  public path:string = "https://crm.turtledownunder.com.au/stagging/";*/

  constructor(private http:HttpClient, private share:SharedService,private route:Router) { 
    // this.check(); 
    // this.initInterval();
  }
   
  login(data):Observable<any>{
    let params={module:"user",view:"check", email : data.username, password : data.password };
      return this.http.post(this.serverUrl,params);
  }

  login_user(data):Observable<any>{
    let params={module:"user",view:"check", email : data.username, password : data.password };
      return this.http.post(this.serverUrl,params);
  }
  createUser(data):Observable<any>{
    return this.http.post(this.serverUrl,data);
  }

  getUserDetails():Observable<any>{
    
    let params={module:"user",view:"details", record:this.currentUserDetails().id};
    return this.http.post(this.serverUrl,params);
  }

  getUserunique(val):Observable<any>{
    
    let params={module:"user",view:"unique", email:val};
    return this.http.post(this.serverUrl,params);
  }

  updateUser(data):Observable<any>{
    return this.http.post(this.serverUrl,data);
  }

  changeUserPassword(data):Observable<any>{
    return this.http.post(this.serverUrl,data);
  }

  currentUserDetails(){
    let usr = sessionStorage.getItem('user');
    return JSON.parse(usr);
  }

  loggedIn(){
   return  !! sessionStorage.getItem('user');
  }

  forgot_Password(data):Observable<any>{
    return this.http.post(this.serverUrl,data);
  }
  session_id(){
   
    let params={module:"check",view:"session",id:this.currentUserDetails().id, sessionId:this.currentUserDetails().sessionid};
    this.http.post(this.serverUrl,params).subscribe(
      (data)=>{ 
        console.log(data);
        if(data==null){
          sessionStorage.removeItem('user');
          this.logoutUser();
          console.log('user not valid');
        }
      },
      err => console.log(err)
    )
  }
  //<!-- retrieve Company Names -->
  companysearch(data):Observable<any>{
    let params={module:"company",view:"list",searchvalue:data};
      return this.http.post(this.serverUrl,params);
  }
  CompanyAddress(){
    let params={module:"company",view:"details",id:this.currentUserDetails().id};
    return this.http.post(this.serverUrl,params);
  }
    logoutUser(){
      this.share.changeUserStatus(false);
      let params={module:"logout",view:"user",id:this.currentUserDetails().id, sessionId:this.currentUserDetails().sessionid};
      this.http.post(this.serverUrl,params).subscribe(
        (data)=>{
          console.log(data);          
        },
        err => console.log(err)
      )
      
      sessionStorage.removeItem('user');
      sessionStorage.clear();
      location.reload();  
    }

    initInterval() {
      setInterval(() => {
        this.check();
      }, CHECK_INTERVAL);
    }
    
    check() {
      
      if(this.loggedIn()){ 
      let login_tim = this.currentUserDetails().login_time;
      const now = Date.now();
      const timeleft = parseInt(login_tim) + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
      const diff = timeleft - now; 
      const isTimeout = diff < 0; 
        if (isTimeout)  {
          this.logoutUser();
        }
      }
    }

    getImageInfo(){
      let params={module:"user",view:"list"};
      return this.http.post(this.serverUrl,params);
    }
}
