import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { UserService } from 'src/app/gen-service/user.service';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class marketingmaterialService {

  constructor(private http:HttpClient, private useSer:UserService) { 
  }

  downloadImage(imageUrl: string): Observable<Blob> {
    return this.http.get(imageUrl, { responseType: 'blob' });
  }

  getImageInfo(){
    let body = {  module : "MarketingMaterial",view : "list" , id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }

  getEducationalResourcesFileInfo(){
    let body = {  module : "MarketingMaterial",view : "show" , id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }

  getEducationalResourcesFilteredFileInfo(selectedResource){
    let body = {  module : "MarketingMaterial",view : "Filtershow" ,"selectedResource" :selectedResource};
    return this.http.post(this.useSer.serverUrl,body); 
  }

  getMarketingMaterialFilteredFileInfo(selectedMaterial){
    let body = {  module : "MarketingMaterial",view : "MaterialFiltershow" ,"selectedMaterial" :selectedMaterial};
    return this.http.post(this.useSer.serverUrl,body); 
  }

  getQuoteList(){
    let body = {  module : "Quotes",view : "list" , id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }
  getInvoiceList(){
    let body = {  module : "Invoices",view : "list" , id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }
  getSalesOrderList(){
    let body = {  module : "SalesOrder",view : "list" , id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }
  getStandardQuoteList(datetr){
    let body = {  module : "Quotes",view : "standardlist" ,"date_of_travel" :datetr, id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }
  getTDUQuoteList(datetr){
    let body = {  module : "Quotes",view : "tdulist" ,"date_of_travel" :datetr, id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }
  getQuoteCountDetails(){
    let body = {  module : "Quotes",view : "count" , id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }

  /*getQuoteDetail(id,quotetype='',$dot='',$mode=''){
    let body = {  module : "Quotes",mode:$mode,view : "detail", record : id,'quotetype':quotetype,'dateoftravel':$dot};
    return this.http.post(this.useSer.serverUrl,body); 
  }*/

  getRecordDetails(id,module){
    let body = {  module : "Record",view : "Detail", record : id,sourcemodule:module};
    return this.http.post(this.useSer.serverUrl,body); 
  }
  getQuoteReject(id,type){
    let body = {  module : "Quotes",view : "Reject", record : id,type:type};
    return this.http.post(this.useSer.serverUrl,body); 

  }

  private selectedPackage = new BehaviorSubject([]);
  parentPackages = this.selectedPackage.asObservable();


  updatePackages(data: any) {
    this.selectedPackage.next(data);
  }
  getPDFFileInfo(module){
    let data = {module :"PDF", view:"List",sourcemodule : module};
    return this.http.post(this.useSer.serverUrl,data); 
  }
  getPDFDownload(module,templateid,recordid){
    let usr = JSON.parse(sessionStorage.getItem('user'));
    let data = {module :"PDF", view:"Download",agentid:usr.id,recordid:recordid,templateid:templateid,sourcemodule : module};
    return this.http.post(this.useSer.serverUrl,data);
   
  }
  downloadPDF(url){
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    this.http.get(url, { headers: headers, responseType: 'blob' });
  }
  getQuoteCatageoryList(cat,from){
    let body = {  module : "Quotes", view : "CatageoryList" ,from:from, catageory:cat, id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body);
  }

  geteditPackageList(item){
    let body = {  module : "package", view : "catageory" , city:item.city, catageory:item.cf_928, id:this.useSer.currentUserDetails().id};
    return this.http.post(this.useSer.serverUrl,body); 
  }

  createQuotes(data){ 
    return this.http.post(this.useSer.serverUrl,data); 
  }

  getTodayDate(){
    
    let today = new Date();
    let dd:any = today.getDate();

    let mm:any = today.getMonth()+1; 
    let yyyy = today.getFullYear();
    if(dd<10) 
    {
        dd='0'+dd;
    } 

    if(mm<10) 
    {
        mm='0'+mm;
    }  
    let dt = yyyy+'-'+mm+'-'+dd; 
    
    return dt;
  }
}
