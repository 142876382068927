import { Component, OnInit, Input } from '@angular/core';
import { UserService } from 'src/app/gen-service/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  public errMsg:any = false;
  public loder:boolean = false;
 // @Input("modelelm") modelelm;
  constructor(private usrSer:UserService, private route:Router) { }

  ngOnInit() {
 
  }
public btnDsbl = false;
  sendEmail(eamil,closeModal){
    this.btnDsbl = true;
    this.errMsg = false;
    this.loder = true;
    this.usrSer.forgot_Password(eamil).subscribe(
      (data)=>{
        this.errMsg = data;
       let res = data.split("@@@");
        if(res[0] == "true"){
         closeModal.click();
          this.errMsg = "";
          this.route.navigate(['/login'], { queryParams: { forgot: 'mail_sent' } })
        }else{
          this.errMsg = res[1];
        }
        this.btnDsbl = false;
      },
      err => console.log(err),
      () => this.loder = false
    )
  }
}
