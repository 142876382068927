import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/gen-service/user.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  constructor(public useSer:UserService) { }
  public al_modules:any;
  ngOnInit() {
    this.al_modules = this.useSer.currentUserDetails().allowed_modules;
  }
  log_out(){
    this.useSer.logoutUser();
  }
}
